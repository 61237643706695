<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row ma-0">
          <div v-if="serverData.organizations" class="col-12 col-md-6 col-lg-4">
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              :items="serverData.organizations"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="organizationIdErrors"
              @blur="$v.formData.organization_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-autocomplete
              v-model="formData.type_id"
              label="Type"
              :items="serverData.types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="typeIdErrors"
              @blur="$v.formData.type_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-text-field
              class
              v-model="formData.system_code"
              label="System code"
              clearable
              outlined
              dense
              :error-messages="systemCodeErrors"
              @input="$v.formData.system_code.$touch()"
              @blur="$v.formData.system_code.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-text-field
              class
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-text-field
              v-model="formData.email"
              label="Email"
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-text-field
              class
              v-model="formData.contact_name"
              label="Contact name"
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-6">
            <VueTelInputVuetify
              class="ma-0 pa-0 mx-1"
              outlined
              v-model="formData.phone"
              :inputOptions="{ showDialCode: false, tabindex: 0 }"
              mode="international"
              dense
              disabledFetchingCountry
              defaultCountry="AE"
            />
          </div>

          <div class="col-12 col-md-6">
            <v-text-field
              class
              v-model="formData.mobile"
              label="Mobile"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <v-autocomplete
              @change="onCountryChange"
              v-model="formData.country_id"
              label="Country"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
              dense
              :error-messages="countryErrors"
              @input="$v.formData.country_id.$touch()"
              @blur="$v.formData.country_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <v-combobox
              v-model="formData.state_id"
              :search-input.sync="state_text"
              :items="serverData.states"
              item-text="title"
              item-value="id"
              label="State"
              persistent-hint
              outlined
              dense
              :error-messages="stateErrors"
              @input="$v.formData.state_id.$touch()"
              @blur="$v.formData.state_id.$touch()"
              @change="onStateChange"
              @keyup="updateValue('state_id')"
              :disabled="isStatesLoaded"
              :rules="[validationValueCheck('state_id')]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ state_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'state',
                            formData.country_id,
                            onCountryChange,
                            state_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <v-combobox
              v-model="formData.city_id"
              :search-input.sync="city_text"
              :items="serverData.cities"
              item-text="title"
              item-value="id"
              label="City"
              persistent-hint
              outlined
              dense
              :error-messages="cityErrors"
              @input="$v.formData.city_id.$touch()"
              @blur="$v.formData.city_id.$touch()"
              @change="onCityChange"
              @keyup="updateValue('city_id')"
              :disabled="isCitiesLoaded"
              :rules="[validationValueCheck('city_id')]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ city_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'city',
                            formData.state_id.id,
                            onStateChange,
                            city_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <v-combobox
              v-model="formData.area_id"
              :search-input.sync="area_text"
              :items="serverData.areas"
              item-text="title"
              item-value="id"
              label="Area"
              persistent-hint
              outlined
              dense
              @keyup="updateValue('collection_area')"
              :disabled="isAreasLoaded"
              :rules="[validationValueCheck('collection_area')]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ area_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'area',
                            formData.city_id.id,
                            onCityChange,
                            area_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-text-field
              v-model="$v.formData.address.$model"
              label="Address 1"
              clearable
              outlined
              dense
              :error-messages="addressErrors"
              @input="$v.formData.address.$touch()"
              @blur="$v.formData.address.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-text-field
              v-model="formData.address_2"
              label="Address 2"
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <v-text-field
              v-model="formData.postal_code"
              label="Postal code"
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12">
            <v-text-field
              v-model="formData.remarks"
              label="Remarks"
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12 py-0">
            <v-switch
              v-model="formData.is_required_additional_validation"
              label="Additional validation required"
              dense
            />
          </div>
          <!-- <div class="col-12 col-md-12">
            <v-text-field
              v-model="formData.note"
              label="Note"
              clearable
              outlined
              dense
            /></v-text-field>
          </div> -->
        </form>

        <CreateLocation
          :location-id="getLocationId"
          :location-type="getLocationType"
          :location-function="getLocationFunc"
          :locationSetValue="setTextValue"
          :initValue="getLocationInitValue"
          ref="location"
        />
        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import CreateLocation from "@/own/components/customers/clients/CreateLocation.vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: {
    VueTelInputVuetify,
    CreateLocation,
  },
  validations() {
    return {
      formData: {
        organization_id: {
          required: requiredIf(function() {
            return !!this.serverData.organizations;
          }),
        },
        type_id: { required },
        system_code: { required },
        name: { required },
        // email: { required, email },
        // contact_name: { required },
        country_id: { required },
        state_id: { required },
        city_id: { required },
        address: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    state_text: "",
    city_text: "",
    area_text: "",

    formData: {
      organization_id: null,
      type_id: null,
      system_code: null,
      name: null,
      email: null,
      contact_name: null,
      phone: null,
      mobile: null,
      remarks: null,
      country_id: null,
      state_id: null,
      city_id: null,
      area_id: null,
      address: null,
      address_2: null,
      postal_code: null,
      is_required_additional_validation: false,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.$v.$reset();
      // this.serverData = null;
      this.state_text = "";
      this.city_text = "";
      this.area_text = "";
      this.formData = {
        organization_id: null,
        type_id: null,
        system_code: null,
        name: null,
        email: null,
        contact_name: null,
        phone: null,
        mobile: null,
        remarks: null,
        country_id: null,
        state_id: null,
        city_id: null,
        area_id: null,
        address: null,
        address_2: null,
        postal_code: null,
        title: null,
        is_required_additional_validation: false,
      };
    },
    loadDataFromServer() {
      // this.$store.dispatch(GET_DATA);
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        ApiService.post(
          "/organization/organization_address_books/edit",
          sendData
        )
          .then(({ data }) => {
            // console.log(data.countries);
            this.serverData = {
              countries: data.countries,
              states: data.states,
              cities: data.cities,
              areas: [...data.areas],
            };
            setTimeout(async () => {
              this.formData = { ...data.organization_address_book };
              // this.formData.country_id = data.address.country_id;
              // this.state_text =
              //   data.organization_address_book.state_id.toString();
              // this.city_text =
              //   data.organization_address_book.city_id.toString();
              // this.area_text =
              //   data.organization_address_book.area_id.toString();

              // this.formData.state_id = { text: "Berat", index: 74 };
              // this.formData.city_id = data.cities.find(
              //   (item) => item.id === data.organization_address_book.city_id
              // );
              // this.formData.area_id = data.areas.find(
              //   (item) => item.id === data.organization_address_book.area_id
              // );

              this.serverData = data;
              //set states
              await this.onCountryChange(this.formData.country_id);
              const state = data.states.find(
                (item) => item.id === this.formData.state_id
              );
              this.formData.state_id = state;

              //set city
              await this.onStateChange(this.formData.state_id);
              const city = this.serverData.cities.find(
                (item) => item.id === this.formData.city_id
              );
              this.formData.city_id = city;

              //set area
              await this.onCityChange(this.formData.city_id);
              const area = this.serverData.areas.find(
                (item) => item.id === this.formData.area_id
              );
              this.formData.area_id = area;

              this.pageLoader(false);
            }, 300);
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        ApiService.post("/organization/organization_address_books/create")
          .then(({ data }) => {
            if (data.success) {
              this.serverData = data;
            }
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "state_id": {
          const item = this.serverData.states.find(
            (state) => state.title === this.state_text
          );

          if (!item) {
            this.onStateChange(item);
          }

          break;
        }

        case "city_id": {
          const item = this.serverData.cities.find(
            (city) => city.title === this.city_text
          );

          if (!item) {
            this.onCityChange(item);
          }
          break;
        }

        case "collection_area": {
          const item = this.serverData.areas.find(
            (area) => area.title === this.area_text
          );

          if (!item) {
            this.formData.area_id = item.title;
          }
          break;
        }
      }
    },
    validationValueCheck(type) {
      let validation = true;
      switch (type) {
        case "state_id":
          try {
            if (
              !this.serverData.states.find(
                (state) => state.title === this.state_text
              )
            ) {
              validation = "Please, select valid state!";
            }
          } catch {
            break;
          }

          break;

        case "city_id":
          try {
            if (
              !this.serverData.cities.find(
                (city) => city.title === this.city_text
              )
            ) {
              validation = "Please, select valid city!";
            }
          } catch {
            break;
          }

          break;

        case "collection_area":
          try {
            if (!this.area_text) {
              validation = true;
              break;
            } else if (
              !this.serverData.areas.find(
                (area) => area.title === this.area_text
              )
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    async setTextValue(type, value = "") {
      switch (type) {
        case "state_id":
          this.state_text = value;

          if (value !== "") {
            this.formData.state_id = this.serverData.states.find(
              (state) => state.title === this.state_text
            );
            await this.onStateChange(this.state_text);
          }

          break;

        case "city_id":
          this.city_text = value;

          if (value !== "") {
            this.formData.city_id = this.serverData.cities.find(
              (city) => city.title === this.city_text
            );
            await this.onCityChange(this.city_text);
          }
          break;

        case "collection_area":
          this.area_text = value;
          break;
      }
    },
    async onCountryChange(val, is_id = false) {
      // console.log(val);
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", {
          ...data,
          organization_id:
            this.formData.organization_id ?? this.currentUserOrganizationId,
        })
          .then((response) => {
            this.serverData.states = response.data.states;

            this.serverData = {
              ...this.serverData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },

    async onStateChange(val, is_id = false) {
      // console.log("onStateChange", val);
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let id = null;
          try {
            id = this.serverData.states.find(
              (state) => state.title === val.title
            ).id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }
          data.state = id;
        }
        this.pageLoader(true);

        await ApiService.post("/address/cities/search", {
          ...data,
          organization_id:
            this.formData.organization_id ?? this.currentUserOrganizationId,
        })
          .then((response) => {
            // this.serverCities = response.data.cities;
            this.serverData.cities = response.data.cities;

            this.serverData = {
              ...this.serverData,
            };

            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },

    async onCityChange(val, is_id = false) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let id = null;
          try {
            id = this.serverData.cities.find((city) => city.title === val.title)
              .id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }

          data.city = id;
        }

        this.pageLoader(true);
        await ApiService.post("/address/areas/search", {
          ...data,
          organization_id:
            this.formData.organization_id ?? this.currentUserOrganizationId,
        })
          .then((response) => {
            this.serverData.areas = response.data.areas;

            this.serverData = {
              ...this.serverData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    createNewItem(type, id, func, value = "") {
      // console.log(type, id, func);
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },

    submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let payload = {
        ...this.formData,
      };
      payload.state_id = this.formData.state_id.id;
      payload.city_id = this.formData.city_id.id;

      payload.area_id = this.formData?.area_id?.id;

      if (this.actionType) {
        payload.id = this.$store.getters.getItemForAction.id;
      }
      // console.log("payload", payload);
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(
        `/organization/organization_address_books/${actionUrl}`,
        payload
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    currentUserOrganizationId: function() {
      return this.$store.getters.currentUser.data.organization_id;
    },
    // if true, we are editing item, false we are adding
    actionType: function() {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function() {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function() {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    organizationIdErrors: function() {
      return this.handleFormValidation("organization_id", this);
    },
    typeIdErrors: function() {
      return this.handleFormValidation("type_id", this);
    },
    systemCodeErrors: function() {
      return this.handleFormValidation("system_code", this);
    },
    nameErrors: function() {
      return this.handleFormValidation("name", this);
    },
    // emailErrors: function() {
    //   return this.handleFormValidation("email", this);
    // },
    // contactNameErrors: function() {
    //   return this.handleFormValidation("contact_name", this);
    // },
    isDataLoaded() {
      return !!this.serverData;
      // return true;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    isStatesLoaded: function() {
      return !this.serverData.states;
    },

    isCitiesLoaded: function() {
      return !this.serverData.cities;
    },

    isAreasLoaded: function() {
      return !this.serverData.areas;
    },

    stateErrors: function() {
      return this.handleFormValidation("state_id", this);
    },
    cityErrors: function() {
      return this.handleFormValidation("city_id", this);
    },
    countryErrors: function() {
      return this.handleFormValidation("country_id", this);
    },
    addressErrors() {
      return this.handleFormValidation("address", this);
    },
  },
};
</script>
